/*@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.CA-logo {
  height: 50px;
  width: 170px;
  margin-top: 32px;
  margin-bottom: 32px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 1000;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
}
.CA-logo > span {
  font-weight: 300;
}